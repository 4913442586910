import React, { useState } from 'react';
import Calendar from 'react-awesome-calendar';
import { Button, Card } from 'semantic-ui-react';
import dayjs from 'dayjs';

const events = [{
  id: 1,
  color: '#935d8c',
  from: '2023-02-10T00:00:01',
  to: '2023-02-10T23:59:59',
  title: "The King's birthday"
}, {
  id: 2,
  color: '#935d8c',
  // tbd
  title: "Umgubho eSwatini"
}, {
  id: 3,
  color: '#935d8c',
  from: '2023-09-30T00:00:01',
  to: '2023-09-30T23:59:59',
  // tbd
  title: 'Heritage - Umgubho waMangwe eTsheni'
}];

const UpcomingEvents = () => {
  const [mode, setMode] = useState<'simple' | 'expanded'>('simple');

  if (mode === 'simple') {
    return (
      <div className="upcoming-events summary">
        {events.map(({ title, from }) => (
          <Card>
            <Card.Content>
              {from
                ? <Card.Meta>{dayjs(from).format('DD MMM YY')}</Card.Meta>
                : <Card.Meta>To Be Announced</Card.Meta>}
              <Card.Description>{title}</Card.Description>
            </Card.Content>
          </Card>
        ))}
        <Button onClick={() => setMode('expanded')}>
          Full Calendar
        </Button>
      </div>
    );
  }

  return (
    <div className="upcoming-events full">
      <Calendar
        events={events.filter(({from}) => !!from)}
      />
      <Button onClick={() => setMode('simple')}>
        Brief Calendar
      </Button>
    </div>
  );
};

export default UpcomingEvents;

import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import image1 from '../assets/images/image_1.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import UpcomingEvents from '../components/UpcomingEvents'
import WhatWeDoTabs from '../components/WhatWeDoTabs'

class Index extends React.Component<{}, { stickyNav: boolean; }> {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout title="Amangwe Economic Revolution">
        <Header
          title="Amangwe"
          subtitle="Economic Revolution"
          showLogo
          hideNav
        />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Amangwe Economic Revolution</h2>
                </header>
                <p>
                  This movement is about the unity of the Amangwe clan, working together in establishing industries to
                  leave a legacy behind for their coming generations.
                </p>
              </div>
              <span className="image">
                <img src={image1} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Who we are</h2>
            </header>
            <p className="content">
              Amangwe is an idea to help bring the family (clan) together in a fight against poverty.
              It allows our family with limited financial resources to pull our resources together and help each other
              out and turn a vision into reality. It is a system or strategy which seeks to emancipate the
              minds of its members by creating a wealth and management system that will allow families to help themselves
              out of abject poverty. The system is designed to be self-sustainable and resilient to failure with family
              members benefiting all the way to bridge the economic divide due to economic disparities which exist in
              the country and continent.

              Membership is allowed to anyone who believes in this project and is committed to work for its sustainability,
              because we believe that unity is the solution to many of challenges we face as the African nation.
            </p>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>What we do</h2>
            </header>
            <p>
              Amangwe is a family dawn from all surnames that constitutes Amangwe clan with a distinctive ancestral
              background and legacy. Despite more than one hundred years of Diaspora, Amangwe still regard themselves as
              a family with a unique history and heritage that must be renewed and preserved.
              It is in this context that Amangwe seeks to build a financial legacy that would benefit the current and
              future generations of Amangwe people. We aim to accomplish this through a number of initiatives:
            </p>
            <WhatWeDoTabs />
          </section>

          <section id="third" className="main special">
            <header className="major">
              <h2>Upcoming Events</h2>
            </header>
            <UpcomingEvents />
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index;

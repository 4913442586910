import { Link } from 'gatsby';
import React from 'react';
import { Card, Image, Tab } from 'semantic-ui-react';
import styled from 'styled-components';

const DETAILS = [
  {
    title: 'Amafu',
    name: 'Amafu Burial Society',
    image: {
      src: '/images/funeral.jpg',
      position: 'center',
    },
    content: "We as the Amangwe family strive to care, comfort and empower each other and when one of us is in pain or no more we bury our own with dignity. As we travel together this journey of life on earth we are quite aware of the fact that any living organism's life will come to cease to exist at some point in life, therefore this family burial society is part of an acknowledgement and an attempt of easing the stress and pain that is left by the dying of a family member. While we cannot undo the passing of a loved one however, we can surely assist with the burying costs or help with the preparations and the burying ceremony.",
    aboutLink: '/about/amafu',
    signUpLink: '/register/amafu',
  }, {
    title: 'Jikelele',
    name: 'Amangwe Jikelele Primary Co-operative Limited',
    image: {
      src: '/images/farm.jpg',
      position: 'bottom',
    },
    content: 'A nation must be able to grow its food in order to survive, and this is a basic right which our Almighty has allowed and created by default. This is an agricultural primary co-operative where Amangwe family produces its food to feed its members, therefore the Amangwe footprint in the agriculture industry is not only a great idea but a necessity. It is also a way of life and our ancestors have paved the way on how to grow crops and take care of livestock which has been the pride and joy of Africans long before the land theft and colonization took place on our continent. Mastering this industry will also aid in unity and development of our continent and to eliminate the politics of the stomach which is a hindrance in the progress of Africans across the globe due to this simple fact, he who feeds you controls you. It is in this token that Amangwe has embarked on a journey of producing and creating its maize meal brand and other opportunities within this sector.',
    aboutLink: '/about/jikelele',
  }, {
    title: 'Omnotho',
    name: 'Amangwe Omnotho Investments',
    image: {
      src: '/images/investment.jpg',
      position: 'center',
    },
    content: 'This is an investment company where Amangwe pull their resources (money) to invest as family/clan/nation. One of the obvious strategy of this company is to make its profits by buying and selling shares, property, bonds, other funds and other assets.',
    aboutLink: '/about/omnotho',
  }
];

const StyledImage = styled(Image)`
  img {
    object-position: ${({ position }) => position};
  }
`;

const TabContent = ({ name, image, content, signUpLink, aboutLink }) => (
  <Card fluid>
    <StyledImage src={image.src} position={image.position} wrapped ui={false} />
    <Card.Content>
      <Card.Header>{name}</Card.Header>
      <Card.Description>{content}</Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Link to={aboutLink} className="ui button">
        Learn More
      </Link>
      {signUpLink ? (<Link to={signUpLink} className="ui button special">
        Sign up
      </Link>) : null}
    </Card.Content>
  </Card>
);

const panes = DETAILS.map(({ title, name, image, content, aboutLink, signUpLink }) => ({
  menuItem: title,
  pane: (
    <Tab.Pane>
      <TabContent name={name} image={image} content={content} aboutLink={aboutLink} signUpLink={signUpLink} />
    </Tab.Pane>
  ),
}));

const WhatWeDoTabs = ({ className }) => (
  <Tab className={className} panes={panes} renderActiveOnly={false} />
);

export default styled(WhatWeDoTabs)`
  .ui.tabular.menu .item {
    font-size: 1.5em;
  }
  .ui.card>{
    .image>img, .ui.cards>.card>.image>img {
      max-height: 300px;
      object-fit: cover;

      @media screen and (max-width: 736px) {
        max-height: 100px;
      }
    }

    .extra.content {
      a {
        margin-right: 10px
      }
    }
  }
`;

import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = ({ sticky }) => (
  <nav id="nav" className={sticky ? 'alt' : ''}>
    <Scrollspy items={['intro', 'first', 'second', 'third']} currentClassName="is-active" offset={-300}>
      <li>
        <Scroll type="id" element="intro">
          <a href="#">Introduction</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="first">
          <a href="#">Who we are</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="second">
          <a href="#">What we do</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="third">
          <a href="#">Upcoming Events</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
);

export default Nav;
